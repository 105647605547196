/** Overrides are for Ant Design styles that are not
accessible through the component scope, and do not
apply to all BW Web Style projects **/

body {
  background-color: #f5f6fa !important; /* Temporary until all apps update bw-web-styles */
}

a {
  color: #005bbb; /* Temporary until all apps update bw-web-styles */
}

textarea::placeholder, input::placeholder {
  color: #757575 !important;  /* Temporary until all apps update bw-web-styles */
}

.bw-popover-inner-content {
  padding: 0 !important;
}

.bw-steps .bw-steps-item-wait:not(.bw-steps-item-active):not(.bw-steps-item-process):not(.bw-steps-item-finish)>.bw-steps-item-container[role='button']:hover .bw-steps-item-icon .bw-steps-icon {
  color: rgba(0, 0, 0, 0.45) !important;
  opacity: 0.6;
  cursor: not-allowed;
}
.bw-steps .bw-steps-item-wait:not(.bw-steps-item-active):not(.bw-steps-item-process):not(.bw-steps-item-finish)>.bw-steps-item-container[role='button']:hover .bw-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.45) !important;
  opacity: 0.6;
  cursor: not-allowed;
}
.bw-steps .bw-steps-item-wait:not(.bw-steps-item-active):not(.bw-steps-item-finish)>.bw-steps-item-container[role='button']:hover .bw-steps-item-title {
  color: rgba(0, 0, 0, 0.45) !important;
  opacity: 0.6;
  cursor: not-allowed;
}

.ant-notification-bottomRight {
  width: fit-content !important;
  margin: 0 auto !important;
  left: 0;
}

.bw-select-dropdown-menu-item-group-title {
  color: #4a4a4a;
  font-size: 14px !important;
  font-weight: bold;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range {
  max-width: 545px;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-footer.bw-calendar-range-bottom {
  display: flex;
  align-items: center;
  height: 84px;

}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-footer.bw-calendar-range-bottom .bw-calendar-footer-btn {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-footer.bw-calendar-range-bottom .bw-calendar-footer-btn .bw-calendar-footer-extra {
  display: flex;
  flex: 1;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-footer.bw-calendar-range-bottom .bw-calendar-footer-btn .bw-calendar-footer-extra.bw-calendar-range-quick-selector {
  background-color: white;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-footer.bw-calendar-range-bottom .bw-calendar-footer-btn .bw-calendar-footer-extra.bw-calendar-range-quick-selector .bw-tag.bw-tag-blue {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.25px;
  text-align: center;
  color: #005bbb;

  width: 128px;
  height: 36px;
  padding-top: 5px;
  margin-right: 20px;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-date-panel .bw-calendar-range-part .bw-calendar-input-wrap .bw-calendar-date-input-wrap .bw-calendar-input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}

.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-date-panel .bw-calendar-range-part .bw-calendar-header,
.bw-calendar-picker-container .bw-calendar.bw-calendar-range .bw-calendar-panel .bw-calendar-date-panel .bw-calendar-range-part .bw-calendar-body {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}

.bw-table-filter-dropdown {
  width: fit-content;
  float: right;
}

.bw-table-thead > tr.bw-table-row-hover:not(.bw-table-expanded-row):not(.bw-table-row-selected) > td, .bw-table-tbody > tr.bw-table-row-hover:not(.bw-table-expanded-row):not(.bw-table-row-selected) > td, .bw-table-thead > tr:hover:not(.bw-table-expanded-row):not(.bw-table-row-selected) > td, .bw-table-tbody > tr:hover:not(.bw-table-expanded-row):not(.bw-table-row-selected) > td {
  background-color: #ECF2FC !important;
}

.mobile-collapsed, .mobile-expanded {
  z-index: 2 !important;
}

header.ant-layout-header {
  z-index: 10;
}

.bw-drawer {
  z-index: 1005 !important;
}

@media only screen and (min-width: 1367px) {
  body {
    overflow-x: hidden;
  }
}

.bw-select-item-option-content {
  font-size: 13px !important;
  font-family: Figtree, sans-serif !important;
}

.bw-tooltip {
  font-family: Figtree, sans-serif !important;
  max-width: 275px !important;
}
